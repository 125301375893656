import React, { createContext, useCallback, useMemo, useState } from 'react';
import { getPublicConfig, updatePublicConfig } from 'apis/config';

const ConfigContext = createContext();

const ConfigProvider = ({ children }) => {
  // DLO: This is what configOptions *should* look like to allow hooks' dependencies to properly track property changes.
  //   Would recommend also Object-ifying the "settings" property if necessary, but I also understand the settings properties
  //   are meant to reflect how they're rendered in the UI.

  const [configOptions, setConfigOptions] = useState({
    announcementBanner: {
      banner: null,
    },
    externalPage: {
      title: null,
      url: null,
    },
    feedback: {
      email: '4VNexusFeedback@4VServices.com',
    },
    matomo: {
      enabled: null,
      matomoUrlBase: null,
      protocol: 'http',
      matomoTrackerUrl: null,
      matomoSrcUrl: null,
    },
    saml: {
      usernameSource: null,
    },
    title: {
      titleType: null,
      title: null,
      titleFavType: null,
      titleFav: null,
    },
    logo: {
      logoType: 'default',
      logoUrl: null,
      logoHeight: '8rem',
      faviconType: 'default',
      faviconUrl: null,
    },
    sayt: {
      // SAYT is enabled by default.
      enabled: true,
    },
    search: {
      facets: [],
      sortOptions: [],
    },
  });

  /**
   * Use setOptions to record option updates during initialization. This will set the Config values, but not send
   * updates to the server.
   * @param {*} options
   */
  const setOptions = useCallback(
    options => {
      setConfigOptions(currentOptions => {
        // For every config property in the incoming options,
        //   replace the value in our current configOptions where valid.
        const newOptions = { ...currentOptions };
        for (const [optionName, optionValue] of Object.entries(options)) {
          for (const [settingName, settingValue] of Object.entries(
            optionValue,
          )) {
            if (newOptions[optionName]?.[settingName] !== undefined) {
              newOptions[optionName][settingName] = settingValue;
            }
          }
        }
        return newOptions;
      });
    },
    [setConfigOptions],
  );

  const updateOptions = useCallback(async () => {
    await updatePublicConfig(configOptions);
    // This getPublicConfig() is used to force-update the middle-tier cache
    //   so our changes are properly reflected across tiers.
    await getPublicConfig({ forceRefresh: true });
  }, [configOptions]);

  const value = useMemo(
    () => ({
      configOptions,
      setOptions,
      updateOptions,
    }),
    [configOptions, setOptions, updateOptions],
  );

  return (
    <ConfigContext.Provider value={value}>{children}</ConfigContext.Provider>
  );
};

export { ConfigContext, ConfigProvider };
